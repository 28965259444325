import { Link } from 'gatsby';
import styled from 'styled-components';

const ReadMoreLink = styled(Link)`
  display: inline-block;
  margin-top: 20px;
  font-size: 20px;
  color: white;
  background-color: #6296cf;
  font-family: 'Berlin Sans FB', sans-serif;
  font-weight: 400;
  padding: 0px 10px;
  text-decoration: none;
`;

export default ReadMoreLink;
