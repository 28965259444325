import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import styled from 'styled-components';
import starterImage from '../img/index-image.jpg';
import Text from '../components/Text';
import ReadMoreLink from '../components/ReadMoreLink';
import { FiHome, FiMail, FiPhone } from 'react-icons/fi';
import routes from '../routes';
import {
  SectionGroup,
  SectionGroupDivider,
  SectionGroupItem,
  SectionTitle,
} from '../components/Section';

interface Props {
  data: {
    introduction: {
      title: string;
      text: string;
    };
    locality: {
      title: string;
      text: string;
    };
    openingHours: {
      title: string;
      text: string;
    };
    contact: {
      title: string;
      address: string;
      phone: string;
      email: string;
    };
    info: {
      title: string;
      spot: string;
      text: string;
    };
  };
}

export const IndexPageTemplate: React.FC<Props> = ({
  data: { introduction, locality, openingHours, contact, info },
}) => (
  <div>
    <ImageAndBoxes>
      <ImageContainer>
        <Image src={starterImage} />
      </ImageContainer>
      <InfoBoxes>
        <InfoBox style={{ backgroundColor: 'rgb(109, 208, 247)' }}>
          <SectionTitle style={{ color: 'white', marginBottom: '6px' }}>
            {openingHours.title}
          </SectionTitle>
          <Text>{openingHours.text}</Text>
        </InfoBox>
        <InfoBox style={{ backgroundColor: 'rgb(141, 199, 63)' }}>
          <SectionTitle style={{ color: 'white', marginBottom: '6px' }}>
            {contact.title}
          </SectionTitle>
          <ContactRow>
            <FiHome />
            <span>{contact.address}</span>
          </ContactRow>
          <ContactRow>
            <FiPhone />
            <a
              href={`tel:${contact.phone}`}
              style={{ color: 'white', textDecoration: 'none' }}
            >
              {contact.phone}
            </a>
          </ContactRow>
          <ContactRow>
            <FiMail />
            <a
              href={`mailto:${contact.email}`}
              style={{ color: 'white', textDecoration: 'none' }}
            >
              {contact.email}
            </a>
          </ContactRow>
        </InfoBox>
        <InfoBox
          style={{
            backgroundColor: 'rgb(234, 100, 26)',
          }}
        >
          <SectionTitle style={{ color: 'white', marginBottom: '6px' }}>
            {info.title}
          </SectionTitle>
          {info.spot && <Spot>{info.spot}</Spot>}
          <i style={{ fontSize: '16px' }}>
            <Text>{info.text}</Text>
          </i>
        </InfoBox>
      </InfoBoxes>
    </ImageAndBoxes>
    <SectionGroup>
      <SectionGroupItem>
        <SectionTitle>{introduction.title}</SectionTitle>
        <Text>{introduction.text}</Text>
        <ReadMoreLink to={routes.about}>weiterlesen</ReadMoreLink>
      </SectionGroupItem>
      <SectionGroupDivider />
      <SectionGroupItem>
        <SectionTitle>{locality.title}</SectionTitle>
        <Text>{locality.text}</Text>
        <ReadMoreLink to={routes.location}>weiterlesen</ReadMoreLink>
      </SectionGroupItem>
    </SectionGroup>
  </div>
);

const ImageAndBoxes = styled.div`
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;

  @media (min-width: 720px) {
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  @media (min-width: 720px) {
    margin-right: 12px;
    flex: 1;
    overflow: hidden;
  }
`;

const Image = styled.img`
  width: 100%;

  @media (min-width: 720px) {
    height: 100%;
    width: auto;
  }
`;

const ContactRow = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;

  svg {
    font-size: 20px;
    margin-right: 16px;
  }

  span {
    font-size: 16px;
  }
`;

const InfoBoxes = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (min-width: 720px) {
    flex: none;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(109, 208, 247);
  padding: 24px 30px;
  font-size: 18px;
  color: white;
  align-items: flex-start;

  @media (min-width: 720px) {
    width: 310px;
  }
`;

const Spot = styled.div`
  background-color: white;
  display: flex;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 20px;
  color: rgb(234, 100, 26);
  font-weight: 600;
  margin: 0 -10px 10px;
`;

const IndexPage: React.FC<{
  data: {
    markdownRemark: {
      frontmatter: any;
    };
  };
}> = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate data={frontmatter} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        introduction {
          title
          text
        }
        locality {
          title
          text
        }
        openingHours {
          title
          text
        }
        contact {
          title
          address
          email
          phone
        }
        info {
          title
          spot
          text
        }
      }
    }
  }
`;
