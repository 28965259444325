import styled from 'styled-components';

export const SectionGroupDivider = styled.div`
  margin: 0 60px;
  width: 2px;
  background-color: hsl(200, 10%, 95%);
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
`;

export const SectionGroup = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const SectionGroupItem = styled.div`
  flex: 1;
  margin-bottom: 30px;

  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 30px;
  color: #6296cf;
  font-family: 'Berlin Sans FB', sans-serif;
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
`;
